import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { BaseService } from '@fitness-central/api/base.service';
import { RoutineType } from '@fitness-central/shared/enum/routine-type.enum';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { MemberWorkoutRequestModel } from './workout.request-model';
import { MemberWorkoutResponseModel } from './workout.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberWorkoutService extends BaseService
{
	public constructor
	()
	{
		super();
	}

	public get(workoutId: string): Observable<MemberWorkoutResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout/${ workoutId }`;

		return this.httpClient.get<MemberWorkoutResponseModel>(url);
	}

	public getAll(startDate: Date, endDate: Date, status: TrainingStatusType): Observable<MemberWorkoutResponseModel[]>
	{
		const startDateString = super.convertToLuxonDateTime(startDate).toISODate();
		const endDateString = super.convertToLuxonDateTime(endDate).toISODate();

		const url = `${ environment.baseUrl  }/member/training/workouts?startDate=${ startDateString }&endDate=${ endDateString }&status=${ status }`;

		return this.httpClient.get<MemberWorkoutResponseModel[]>(url);
	}

	public getDaily(startDate: Date, endDate: Date, status: TrainingStatusType): Observable<MemberWorkoutResponseModel[]>
	{
		const startDateString = super.convertToLuxonDateTime(startDate).toISODate();
		const endDateString = super.convertToLuxonDateTime(endDate).toISODate();

		const url = `${ environment.baseUrl  }/member/training/workouts?routineType=${ RoutineType.Daily }&startDate=${ startDateString }&endDate=${ endDateString }&status=${ status }`;

		return this.httpClient.get<MemberWorkoutResponseModel[]>(url);
	}

	public getByRoutineIdAndWorkoutStatus(routineId: string, status: TrainingStatusType): Observable<MemberWorkoutResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workouts/routine/${ routineId }?status=${ status }`;

		return this.httpClient.get<MemberWorkoutResponseModel[]>(url);
	}

	public create(request: MemberWorkoutRequestModel): Observable<MemberWorkoutResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout`;

		return this.httpClient.post<MemberWorkoutResponseModel>(url, request);
	}

	public createFromRoutine(routineId: string): Observable<MemberWorkoutResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout/routine/${ routineId }`;

		return this.httpClient.post<MemberWorkoutResponseModel>(url, {});
	}

	public update(request: MemberWorkoutRequestModel): Observable<MemberWorkoutResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout/${ request.workoutId }`;

		return this.httpClient.put<MemberWorkoutResponseModel>(url, request);
	}
}
