import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { MemberWorkoutResistanceExerciseSetRequestModel } from './workout-resistance-exercise-set.request-model';
import { MemberWorkoutResistanceExerciseSetResponseModel } from './workout-resistance-exercise-set.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberWorkoutResistanceExerciseSetService
{
	private http = inject(HttpClient);


	public getByWorkoutResistanceExerciseId(workoutResistanceExerciseId: string): Observable<MemberWorkoutResistanceExerciseSetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-sets/workout-resistance-exercise/${ workoutResistanceExerciseId }`;

		return this.http.get<MemberWorkoutResistanceExerciseSetResponseModel[]>(url);
	}

	public create(request: MemberWorkoutResistanceExerciseSetRequestModel): Observable<MemberWorkoutResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set`;

		return this.http.post<MemberWorkoutResistanceExerciseSetResponseModel>(url, request);
	}

	public delete(workoutResistanceExerciseSetId: string): Observable<MemberWorkoutResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set/${ workoutResistanceExerciseSetId }`;

		return this.http.delete<MemberWorkoutResistanceExerciseSetResponseModel>(url);
	}

	public update(request: MemberWorkoutResistanceExerciseSetRequestModel): Observable<MemberWorkoutResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-set/${ request.workoutResistanceExerciseSetId }`;

		return this.http.put<MemberWorkoutResistanceExerciseSetResponseModel>(url, request);
	}

	public updateRange(request: MemberWorkoutResistanceExerciseSetRequestModel[]): Observable<MemberWorkoutResistanceExerciseSetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise-sets`

		return this.http.put<MemberWorkoutResistanceExerciseSetResponseModel[]>(url, request);
	}
}
