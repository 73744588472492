import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { MemberWorkoutResistanceExerciseRequestModel } from './workout-resistance-exercise.request-model';
import { MemberWorkoutResistanceExerciseResponseModel } from './workout-resistance-exercise.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberWorkoutResistanceExerciseService
{
	private http = inject(HttpClient);


	public get(workoutResistanceExerciseId: string): Observable<MemberWorkoutResistanceExerciseResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise/${ workoutResistanceExerciseId }`;

		return this.http.get<MemberWorkoutResistanceExerciseResponseModel>(url);
	}

	public getByWorkoutId(workoutId: string): Observable<MemberWorkoutResistanceExerciseResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercises/workout/${ workoutId }`;

		return this.http.get<MemberWorkoutResistanceExerciseResponseModel[]>(url);
	}

	public create(request: MemberWorkoutResistanceExerciseRequestModel) : Observable<MemberWorkoutResistanceExerciseResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise`;

		return this.http.post<MemberWorkoutResistanceExerciseResponseModel>(url, request);
	}

	public delete(workoutResistanceExerciseId: string) : Observable<MemberWorkoutResistanceExerciseResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise/${ workoutResistanceExerciseId }`;

		return this.http.delete<MemberWorkoutResistanceExerciseResponseModel>(url);
	}

	public update(request: MemberWorkoutResistanceExerciseRequestModel) : Observable<MemberWorkoutResistanceExerciseResponseModel>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise/${ request.workoutResistanceExerciseId }`;

		return this.http.put<MemberWorkoutResistanceExerciseResponseModel>(url, request);
	}

	public updateRange(request: MemberWorkoutResistanceExerciseRequestModel[]) : Observable<MemberWorkoutResistanceExerciseResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/member/training/workout-resistance-exercise`

		return this.http.put<MemberWorkoutResistanceExerciseResponseModel[]>(url, request);
	}
}
